.portingFormLayout {
  display: flex;
  flex-direction: column !important;
  margin: 0 0 0 20px !important;
  width: 100%;
}

.portingFormLayout .fieldStyle {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(99, 99, 99, 0.25);
}

.fieldStyle .fieldTitle {
  width: 30%;
  padding-left: 15px;
  font-weight: 300;
  background-color: rgba(99, 99, 99, 0.2);
  padding-top: 1%;
}

.fieldStyle .fieldValue {
  padding: 4px 6px;
  text-align: left;
  vertical-align: top;
  border-bottom: 1px solid rgba(99, 99, 99, 0.25);
  font-weight: 300;
}
