body {
  .sidebar {
    position: fixed;
    right: 0;
    background: #000;
    top: 0;
    bottom: 0;
    max-width: 400px;
    width: 100%;
    display: none;
  }
}