.category {
  .title {
    color: #ffffff;
    padding: 10px;
    background: #222428;
    cursor: pointer;
    .icon {
      margin-right: 10px;
    }
    .arrow {
      float: right;
    }
  }
  .collapse {
    display: none !important;
  }
}

nav {
  display: block;
  position: fixed;
  left: 0;
  top: 42px;
  bottom: 0;
  background: #222428;
  width: 220px;

  a {
    display: block;
    text-decoration: none;
    padding: 7px 12px 7px 29px;
    background: #33373e;
    border-bottom: 1px solid rgba(250, 250, 250, .1);
    color: #ffffff;
    transition: all .25s;
    border-left: 5px solid transparent;

    &:hover{
      background: rgba(255,255,255,.05);
    }

    &.active {
      background: rgba(255,255,255,.05);
      border-left-color: white;
      border-width: 2px;
    }
   
  }
}