$footer-height: 40px;

body {
  .alerts {
    position: absolute;
    width: 460px;
    max-height: 500px;
    overflow: auto;
    top: 200px;
    left: 50%;
    margin-left: -230px;
    z-index: 9;

    .alert {
      display: block;
      width: 100%;
      background: #f0f0f0;
      box-shadow: 0 0 10px rgba(0,0,0,.5);

      .inner-content {
        padding: 30px 20px;
        text-align: center;
        font-size: 18px;

        .option-check{
          display: flex;
          align-items: left;
          gap: 0.3rem;
          padding-top: 10px;
          padding-left: 1.5rem;
        }
      }


      .footer {
        height: $footer-height;
        background: rgba(99, 99, 99, 0.25);

        .btn {
          padding: 11px 0;
          text-transform: uppercase;
          color: #ffffff;
          background: #999999;
          cursor: pointer;
          float: left;
          width: 50%;
          text-align: center;

          &.save {
            background: #666666;
            float: right;
          }
        }

        &.wide {
          .btn {
            width: 100%;
          }
        }
      }

      &.ALERT_CONFIRM {
        .footer {
          .btn {
            &.save {
              background: #b10c0c;
            }
          }
        }
      }

    }
  }
}
