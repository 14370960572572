form {
  .navision_id_form {
    input {
      width: 100px !important;
    }
  }

  .hover-info {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #804da8;
    padding: 10px;
    z-index: 3;

    h4 {
      padding: 0;
      margin: 0;
      font-weight: bold;
    }

    p {
      font-size: 14px;
      padding: 0;
      margin: 5px 0 0;
    }
  }

  .inner-content {
    padding: 10px 0px 200px;

    .sup {
      opacity: 0.7;
      font-size: 75%;
      padding: 0 2px 5px;
    }

    & > table > tbody > tr > th {
      width: 160px;
      padding-left: 15px;
      font-weight: 300;
    }

    tr .icon {
      margin-left: 5px;
      opacity: 0.5;
      display: block;
      font-size: 22px;
    }
  }

  .period {
    select {
      display: block;
      margin-bottom: 2px;
    }
    .dates {
      display: block;
      overflow: hidden;
      clear: both;
    }
  }

  .interval {
    input {
      width: 70px !important;
      text-align: center;
    }
  }

  input,
  select {
    float: left;
  }

  input,
  select {
    font-size: 14px;
    margin-right: 2px;
  }

  input {
    padding: 5px 8px;
    border: 0;
    background: rgba(255, 255, 255, 0.95);
    width: 180px;
  }

  textarea {
    @extend input
  }

  input:focus {
    background: rgba(255, 255, 255, 1);
  }

  input[type="number"] {
    width: 40px;
    text-align: center;
    padding-right: 0;
    padding-left: 5px;
  }

  select {
    padding: 3px 8px;
    border: 1px solid #fff;
    width: 180px;
  }

  .switch {
    width: 34px;
    height: 16px;
    position: relative;
    margin: 6px 10px 5px 0;
    cursor: pointer;
    float: left;

    &.disabled {
      opacity: 0.5;
    }

    .bg {
      position: absolute;
      left: 0;
      right: 0;
      top: 3px;
      bottom: 3px;
      background-color: #888888;
      border-radius: 5px;
      z-index: 0;
    }

    .ball {
      width: 17px;
      top: 0;
      bottom: 0;
      margin: 0 3px;
      background: #cccccc;
      border-radius: 50%;
      z-index: 1;
      position: absolute;
    }

    &.checked {
      .ball {
        right: 0;
      }
      .bg {
        background: green;
      }
    }
  }

  .input-color {
    display: block;
    float: left;
    margin: 3px;
    .color-picker {
      width: 45px;
      height: 20px;
      margin: 0 6px;
    }
  }

  .input-number {
    input {
      float: left;
    }
    .minus,
    .plus,
    .percentage {
      line-height: 28px;
      color: #ffffff;
      float: left;
      width: 15px;
      text-align: center;
      cursor: pointer;
    }

    .percentage {
      opacity: 0.7;
    }
  }

  .react-datepicker-wrapper {
    input {
      width: 120px;
    }
  }

  th .typeahead {
    min-width: 160px;
  }

  .typeahead {
    background: rgba(255, 255, 255, 0.85);
    position: relative;
    float: left;
    min-width: 240px;
    max-width: 420px;

    input {
      background: transparent;
      min-width: 70px;
      float: left;
    }

    .focus-area {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 0;
    }
    .selected {
      overflow: hidden;

      .option {
        max-width: 100px;
        min-width: 70px;
        height: 23px;
        overflow: hidden;
        border: 1px solid #1f42a9;
        background: #1f42a9;
        float: left;
        margin: 2px 0 2px 2px;
        font-size: 13px;
        padding: 3px 5px;
        position: relative;
        transition: color 0.25s;
        text-align: center;

        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */

        .txt {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }

        &:hover {
          color: rgba(255, 255, 255, 0.55);
          .icon-close,
          .icon-edit,
          .icon-move {
            display: block;
          }
        }

        .icon-close,
        .icon-edit,
        .icon-move {
          position: absolute;
          display: none;
          top: 0;
          font-size: 14px;
          padding: 4px;
          background: rgba(24, 51, 102, 0.5);
          color: #ffffff;
        }

        .icon-close {
          right: 0;
        }

        .icon-edit {
          left: 0;
        }

        .icon-close,
        .icon-edit {
          width: 25px;
        }

        .icon-move {
          left: 25px;
          right: 25px;
          cursor: move;
          color: rgba(255, 255, 255, 0.9);
        }
      }
    }

    .options {
      position: absolute;
      width: 100%;
      max-width: 320px;
      min-width: 160px;
      max-height: 300px;
      overflow-y: auto;
      overflow-x: hidden;
      z-index: 2;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

      .option {
        width: 100%;
        background: #eee;
        color: #000000;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        font-size: 14px;
        padding: 3px 7px;
        cursor: pointer;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        .icon {
          margin-right: 5px;
        }

        &:hover {
          background: #ffffff;
        }
      }

      .group {
        background: #ccc;
        color: #000;
        text-transform: uppercase;
        padding: 2px 5px;
        font-size: 12px;
        font-weight: bold;
        .icon {
          margin-right: 5px;
        }
      }
    }
  }

  .nested_metrics {
    .nested_metric {
      &:hover {
        .icon-delete {
          display: block;
        }
      }

      position: relative;
      float: left;

      .icon-delete {
        position: absolute;
        bottom: 2px;
        right: 2px;
        color: #236;
        display: none;
      }

      .typeahead {
        width: 106px;
        min-width: 106px;
        min-height: 90px;
        margin-right: 2px;

        .selected {
          .option {
            width: 100px;
          }
        }
      }
    }
  }

  .helpers {
    font-size: 12px;
    list-style-type: none;
    padding: 0;
    margin: 10px 5px;
    li {
      font-size: 14px;
    }
  }
}

.field-border {
  border: 2px solid #ddd;
}
.quantity-input {
  padding: 3px 8px;
  width: 60px;
  height: 29px;
}

.select {
  padding: 3px 8px;
  width: 180px;
  font-size: 14px;
}
.select-org {
  width: 300px !important;
}
.checkbox {
}

.input-select{
  padding: 5px;
  width: 180px;
  margin: 5px;
  border-radius: 3px;
}