// Utility classes
// Inspired by tailwind
// https://nerdcave.com/tailwind-cheat-sheet

// Mixins
@mixin sizes($classname-properties-map, $size-value-map, $default-size) {
  @each $classname, $properties in $classname-properties-map {
    .#{$classname} {
      @each $property in $properties {
        #{$property}: #{$default-size};
      }
    }
    @each $size, $value in $size-value-map {
      .#{$classname}-#{$size} {
        @each $property in $properties {
          #{$property}: #{$value};
        }
      }
    }
  }
}

@mixin sizes-generalized($classname, $property, $sizes) {
  @each $size in $sizes {
    $abs-size: abs($size);
    .#{$classname}-#{$abs-size} {
      #{$property}: #{$size * 0.25}rem;
    }
    .#{$classname}t-#{$abs-size} {
      #{$property}-top: #{$size * 0.25}rem;
    }
    .#{$classname}b-#{$abs-size} {
      #{$property}-bottom: #{$size * 0.25}rem;
    }
    .#{$classname}l-#{$abs-size} {
      #{$property}-left: #{$size * 0.25}rem;
    }
    .#{$classname}r-#{$abs-size} {
      #{$property}-right: #{$size * 0.25}rem;
    }
    .#{$classname}x-#{$abs-size} {
      #{$property}-left: #{$size * 0.25}rem;
      #{$property}-right: #{$size * 0.25}rem;
    }
    .#{$classname}y-#{$abs-size} {
      #{$property}-top: #{$size * 0.25}rem;
      #{$property}-bottom: #{$size * 0.25}rem;
    }
  }
}

// Layout
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.hidden {
  display: none !important;
}
.z-0 {
  z-index: 0;
}
.z-10 {
  z-index: 10;
}
.z-20 {
  z-index: 20;
}
.z-30 {
  z-index: 30;
}

// Flexbox
.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.justify-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.self-start {
  align-self: flex-start;
}
.self-end {
  align-self: flex-end;
}
.flex-1 {
  flex: 1 0 0%;
}
.flex-auto {
  flex: 1 1 auto;
}
.flex-initial {
  flex: 0 1 auto;
}
.flex-none {
  flex: none;
}
.flex-wrap {
  flex-wrap: wrap;
}

// Spacing
@include sizes-generalized(
  "m",
  "margin",
  (0, 1, 2, 3, 4, 5, 6, 7, 8, 10, 12, 14, 16)
);
@include sizes-generalized(
  "-m",
  "margin",
  (-1, -2, -3, -4, -5, -6, -7, -8, -10, -12, -14, -16)
);
@include sizes-generalized(
  "p",
  "padding",
  (1, 2, 3, 4, 5, 6, 8, 10, 12, 14, 16)
);

// Typography
.text-center {
  text-align: center;
}
.whitespace-no-wrap {
  white-space: nowrap;
}
.text-xs {
  font-size: 0.75rem;
}
.text-sm {
  font-size: 0.875rem;
}
.text-base {
  font-size: 1rem;
}
.text-lg {
  font-size: 1.125rem;
}
.text-xl {
  font-size: 1.25rem;
}
.text-2xl {
  font-size: 1.5rem;
}
.text-3xl {
  font-size: 1.875rem;
}
.text-4xl {
  font-size: 2.25rem;
}
.text-white {
  color: #fff;
}

// Borders
$border-radius-classname-properties-map: (
  "rounded": (
    "border-radius"
  ),
  "rounded-t": (
    "border-top-left-radius",
    "border-top-right-radius"
  ),
  "rounded-b": (
    "border-bottom-left-radius",
    "border-bottom-right-radius"
  )
);
$border-radius-size-value-map: (
  "sm": 0.125rem,
  "md": 0.375rem,
  "lg": 0.5rem
);
@include sizes(
  $border-radius-classname-properties-map,
  $border-radius-size-value-map,
  0.25rem
);
.border-0 {
  border-width: 0px;
}
.border-2 {
  border-width: 2px;
}
.border-4 {
  border-width: 2px;
}
.border-gray-600 {
  border-color: #718096;
}

// Interactivity
.cursor-pointer,
.clickable {
  cursor: pointer;
}
.select-none {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

// Sizing
.w-full {
  width: 100%;
}

// Backgrounds
.bg-white {
  background-color: white;
}
.bg-gray-100 {
  background-color: #f7fafc;
}
.bg-gray-200 {
  background-color: #edf2f7;
}
.bg-gray-300 {
  background-color: #e2e8f0;
}
.bg-gray-400 {
  background-color: #cbd5e0;
}
.bg-gray-500 {
  background-color: #a0aec0;
}
.bg-gray-600 {
  background-color: #718096;
}
.bg-gray-700 {
  background-color: #4a5568;
}
.bg-gray-800 {
  background-color: #2d3748;
}
.bg-gray-900 {
  background-color: #1a202c;
}

// Shadows
.shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.stickyheader thead tr th{
  position: sticky;
  top: 44px;
}