.cart-icon-container {
  color: #333333;
  display: flex;
  align-items: center;

  .cart-icon {
    font-size: 1.5rem;
  }

  .cart-badge {
    margin-left: 5px;
  }
}
.loading-inventory {
  margin: 0.5rem;
  font-size: 1.2rem;
}
