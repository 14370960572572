@font-face {
    font-family: 'Myriad Pro';
    font-style: normal;
    font-weight: 100;
    src: local('Myriad Pro'), url('MYRIADPRO-LIGHT.woff') format('woff');
}

@font-face {
    font-family: 'Myriad Pro';
    font-style: italic;
    font-weight: 100;
    src: local('Myriad Pro'), url('MYRIADPRO-REGULARIT.ttf') format('true-type');
}

@font-face {
    font-family: 'Myriad Pro';
    font-style: normal;
    font-weight: 300;
    src: local('Myriad Pro'), url('MYRIADPRO-REGULAR.woff') format('woff');
}

@font-face {
    font-family: 'Myriad Pro';
    font-style: italic;
    font-weight: 300;
    src: local('Myriad Pro'), url('MYRIADPRO-REGULARIT.ttf') format('true-type');
}

@font-face {
    font-family: 'Myriad Pro';
    font-style: normal;
    font-weight: 700;
    src: local('Myriad Pro'), url('MYRIADPRO-BOLD.woff') format('woff');
}

@font-face {
    font-family: 'Myriad Pro';
    font-style: italic;
    font-weight: 700;
    src: local('Myriad Pro'), url('MYRIADPRO-BOLDIT.woff') format('woff');
}

@font-face {
    font-family: 'Myriad Pro';
    font-style: normal;
    font-weight: 900;
    src: local('Myriad Pro'), url('MYRIADPRO-SEMIBOLD.woff') format('woff');
}

@font-face {
    font-family: 'Myriad Pro';
    font-style: italic;
    font-weight: 900;
    src: local('Myriad Pro'), url('MYRIADPRO-SEMIBOLDIT.woff') format('woff');
}