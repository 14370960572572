.chartsRow {
    // display: flex;
    // justify-content: space-around;
    // margin-bottom: 40px;
}
.charts{
    display: grid;
    grid-template-columns: auto auto;
    justify-items: center;
}

.table-container {
  width: calc(100% - 20px); /* Set the width of the container */
  padding: 30px 20px;
  box-sizing: border-box;
  overflow: auto; /* Enable vertical and horizontal scrollbar if content overflows */
  position: relative; /* Ensure positioning context for thead */
}

.table-container table {
  width: 100%; /* Set the width of the table to fill the container */
  /* Additional table styles */
}

.table-container thead {
  position: sticky; /* Make the header sticky */
  top: 0; /* Stick the header to the top of the container */
  background-color: #f2f2f2; /* Optional: Style the sticky header background */
}

.table-container tbody {
  height: 100%; /* Set the height of tbody to control the visible area */
  overflow-y: auto; /* Enable vertical scrollbar for tbody */
}
