@media (max-width:600px) {
  body {
    #root div > .content {
      padding: 10px;
    }

    .content {
      padding: 0;
    }

    header {
      .mobile-menu {
        display: block;
      }

      .left, .right, .center .date, .center .time {
        display: none;
      }

      .center {
        width: 68px;
      }
    }

    .dotmenu {
      opacity: 1 !important;
    }

    .popup {
      form {
        .inner-content > table {
          > tbody {
            > tr {
              > th, td {
                display: block;
                width: 100% !important;
              }

              > th {
                font-size: 14px;
                padding: 2px 8px;
                text-transform: uppercase;
                opacity: .7;
              }

              td {
                padding-bottom: 3px;
              }
            }
          }
        }
      }
    }

  }
}
