header {
  background: #804da8;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1;

  .icon {
    padding: 8px;
    display: inline-block;
    color: #ffffff;
    font-size: 24px;
  }

  .left,
  .center,
  .right {
    width: 250px;
    height: 40px;
    padding: 0 5px;
  }

  .left {
    width: 250px;

    .delimiter {
      width: 1px;
      height: 18px;
      background: #666666;
      display: block;
      float: left;
      margin: 12px 10px;
    }

    .icon {
      display: block;
      float: left;
    }
  }

  .box {
    padding: 2px 6px;
    background: #236;
    color: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.5);
    margin: 8px 2px;
    display: block;
    float: left;
    min-width: 25px;
    text-align: center;

    &.light {
      background: #e6e6e6;
      color: #236;
    }

    &.sv {
      background: rgba(255, 235, 59, 0.4);
    }
  }

  .center {
    text-align: center;
    margin: 0 auto;

    .logo,
    .date,
    .time {
      float: left;
      color: #ffffff;
    }

    .logo {
      padding: 5px 4px 1px;
      margin: 0 7px 0 13px;
      display: block;
    }

    .date,
    .time {
      line-height: 41px;
      font-size: 18px;
      font-weight: 100;
    }
  }

  .right {
    position: absolute;
    right: 0;
    top: 0;
    text-align: right;

    .user {
      color: #cccccc;
      display: inline-block;
      padding: 8px;
      margin-top: -3px;
      line-height: 32px;
      font-size: 14px;
    }

    .user,
    .icon {
      float: right;
    }
  }

  .left {
    float: left;
    display: none;
  }

  .mobile-menu {
    z-index: 1;
    position: absolute;
    right: 0;
    opacity: 0;
    .button {
      overflow: hidden;
      padding: 0;
      .icon {
        float: right;
        font-size: 18px;
        padding: 11px;
      }
    }
    .menu {
      ul {
        background: #236;
        border-color: #000000;
        color: #ffffff;
        li {
          border-color: #000000;
          .box {
            margin: 0 3px 0 0;
            padding: 1px 4px;
          }
          .icon-language,
          .icon-palette {
            line-height: 21px;
          }
        }
      }
    }
  }

  .mobile-menu {
    display: none;
  }
}
