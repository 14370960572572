.modal {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }
  
  .modal__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    /* background-color: black; */
    cursor: pointer;
  }
  
  .modal__box {
    position: absolute;
    width: 80%;
    margin: 0 10%;
    padding: 0px;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: transparent;
    cursor: auto;
  }
  
  .modal__title {
    color: #804DA8;
    font-size: 30px;
  }
  
  .modal__content {
    margin-top: 30px;
    color: #6B6B6B;
    font-size: 16px;
  }
  
  .modal__close {
    border-radius: 2px;
    height: 18px;
    font-size: 14px;
    position: absolute;
    top: 20px;
    left: 20px;
    margin-left: 140%;
    margin-top: -2%;
    transition: transform 250ms ease-in-out;
    transform-origin: 50% 50%;
    background-color: transparent;
    border: 1px solid grey;
  }
  .modal__close:active {
    background-color: #6B6B6B;
    color: white;
  }
  /* .modal__close:hover {
    transform: rotate(180deg);
  } */
  
  @media screen and (min-width: 800px) {
    .modal__box {
      max-width: 600px;
      margin: 0 30px;
    }
  }
.default-button {
  color: #ffffff;
  background-color: #804DA8;
  font-size: 16px;
  display: inline-block;
  padding: 5px 15px;
  margin: 1px;
  cursor: pointer;
  border: white;
  border-radius: 4px;;
}
.default-button:active {
  background-color: #ffffff;
  color: #804DA8;
  border: 1px solid #804DA8
}