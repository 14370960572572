.notification {
  margin: 10px 12px;
  border-radius: 4px;
  padding: 8px 10px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  hr {
    border-color: #ffffff52;
  }
  h3 {
    font-family: sans-serif;
    margin-bottom: 10px;
  }
  p {
    font-size: 12px;
    margin-bottom: 15px;
  }
  h6 {
    color: gray;
    font-size: 12px;
    span {
      font-weight: bold;
    }
    .delimeter {
      margin: 0 10px;
    }
  }
  .icon-delete {
    float: right;
    cursor: pointer;
    font-size: 22px;
  }

  .container {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    width: 90%;
    height: 90vh;
  }

  .container i {
    padding: 20px;
    display: block;
    font-size: 100px;
    transition: all 0.4s ease;
    background-color: black;
  }

  .container i:hover {
    transform: rotateZ(-90deg);
  }
  .basic-table {
    width: 100%;
    border-collapse: collapse;
  }
  .basic-table thead tr {
    background-color: lightgrey;;
  }
  .basic-table thead tr td {
    color: grey;
    padding: 5px;
    font-size: 12px;;
  }
  .basic-table tbody tr td {
    color: gray;
    padding: 5px;
    font-size: 12px;;
  }
}
.notification-button{
  padding: 10px;
  margin: 0 5px;
}
.search-box{
  display: flex;
}

.checkbox-main {
  margin: 5px;
  transform: scale(1.5);
  accent-color: black
}

.search-box{
  width: 100%;
  padding: 10px 20px;
}
.search-box-input{
  font: inherit;
  padding: 4px 14px;
  letter-spacing: inherit;
  color: currentColor;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.23);
  box-sizing: content-box;
  background: none;
  margin: 0 10px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
}

.notification-disabled{
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12);
  pointer-events: none;
}

.individual-checkbox{
  accent-color: black;
  width: 14px;
  height: 14px;
  margin-right: 10px;
}