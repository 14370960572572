.dotmenu {

  z-index: 2;
  font-size: 14px;
  min-width: 130px;

  .button {
    padding: 5px;
  }
  .menu {
    display: none;

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      font-size: 14px;
      background: #ffffff;
      color: #000000;
      border: 1px solid #f0f0f0;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);

      li {
        padding: 5px 10px;
        border-bottom: 1px solid #f0f0f0;
        cursor: pointer;
        text-align: left;
        overflow: hidden;

        .icon {
          padding: 0 5px 0 0;
          font-size: 14px;
          line-height: 16px;
          display: inline-block;
          float: left;
        }

        &:last-child {
          border-bottom: 0;
        }

        &:hover {
          background: #f0f0f0;
        }

        .icon {
          opacity: .7;
        }
      }
    }
  }

  &.show {
    .menu {
      display: block;
    }
  }
}