$footer-height: 40px;

.popup {
  position: fixed;
  max-width: 640px;
  width: 100%;
  transform: translateX(0px);
  top: 0;
  bottom: 0;
  background: #236;
  color: #fff;
  padding: 10px 0;
  box-shadow: 0 0 10px rgba(0,0,0,.5);
  z-index: 9;
  transition: transform .45s;

  .close {
    position: absolute;
    right: -60px;
    top: 0;
    opacity: .75;
    cursor: pointer;
    .icon-close {
      font-size: 48px;
    }
  }

  form {
    .inner-content {
      position: absolute;
      top: 0;
      bottom: 40px;
      left: 0;
      right: 0;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  h2 {
    text-align: center;
    margin: 15px 0 15px;
  }

  table {

    border-collapse: collapse;
    border-spacing: 0;

    td, th {
      padding: 4px 6px;
      &.nopadding {
        padding-top: 0;
        padding-bottom: 0;
      }
      text-align: left;
      vertical-align: top;
      border-bottom: 1px solid rgba(99, 99, 99, 0.25);
      font-weight: 300;
    }

    table {
      td, th {
        border-bottom: 0;
      }
    }

    tbody td p {
      display: flex;
      margin-top: 4px;
      align-items: center;
      justify-content: center;
    }
    thead {
      th {
        background-color: rgba(99, 99, 99, 0.35);
        padding: 4px 6px;
      }
    }

    tbody {
      th {
        background-color: rgba(99, 99, 99, 0.2);
        &.delimiter {
          background-color: rgba(99, 99, 99, 0.1);
          font-size: 14px;
          padding: 8px 6px 2px;
          text-transform: uppercase;
          opacity: .7;
        }
      }
      .button-validate{
        margin-top: 4px;
      }
      .logo-validation{
        margin-top:5px;

        .logo-validation-message{
          margin: 0 10px;
          cursor: pointer;
        }
      }
    }


  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: $footer-height;
    background: rgba(99, 99, 99, 0.25);
    z-index: 3;

    .btn {
      padding: 11px 0;
      text-transform: uppercase;
      color: #ffffff;
      background: #999999;
      cursor: pointer;
      float: left;
      width: 50%;
      text-align: center;

      &.save {
        background: #008000;
        float: right;
      }

      &.ok {
        background: #555555;
        float: right;
      }
    }
  }
}