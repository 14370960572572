

.switch {
  width: 34px;
  height: 16px;
  position: relative;
  margin: 6px 10px 5px 0;
  cursor: pointer;
  float: left;

  &.disabled {
    opacity: 0.5;
  }

  .bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 3px;
    bottom: 3px;
    background-color: #888888;
    border-radius: 5px;
    z-index: 0;
  }

  .ball {
    width: 17px;
    top: 0;
    bottom: 0;
    margin: 0 3px;
    background: #cccccc;
    border-radius: 50%;
    z-index: 1;
    position: absolute;
  }

  &.checked {
    .ball {
      right: 0;
    }
    .bg {
      background: green;
    }
  }
}




.vb-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2px 0;
  gap: 20px;
  .title {
    width: 300px;
    font-size: 1rem;
    // font-weight: 600;
    padding: 6px;
  }
}

.vb-host {
  input {
    width: 300px;
    padding: 8px 16px;
    border-radius: 5px;
    border: 1px solid #dddddd;
    border-color: rgba(0, 0, 0, 0.23);
    .icon-close {
      color: rgba(0, 0, 0, 0.26);
    }
    .options {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  
      .option {
        background: white !important;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2) !important;
        padding: 10px 20px !important;
  
        .icon {
          margin-right: 5px;
        }
  
        &:hover {
          background: #eee !important;
        }
      }
  
      .group {
        padding: 10px !important;
        border-left: 3px solid rgb(0, 0, 0);
        background: white !important;
        color: #000;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        .icon {
          margin-right: 5px;
        }
      }
    }
  }
  input:focus {
    border-color: rgba(0, 0, 0, 0.3);
    border-width: 2.5px;
  }
  .action {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  .main {
    min-height: 100px;
    padding: 10px;
  }
  .save-button {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 4px;
    font-weight: 700;
    background-color: #804da8;
    color: #ffffff;
    margin: 1px;
    cursor: pointer;
    font-size: 16px;
  }
  .save-button:hover {
    background-color: #161194;
  }
}
