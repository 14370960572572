@import "../fonts/MYRIADPRO/style.css";
$padding: 35;

* {
  font-family: "Myriad Pro", sans-serif;
  box-sizing: border-box;
  outline: none;
  font-weight: 300;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.clickable {
  cursor: pointer;
}

.downloadButton {
  display: inline-block;
  padding: 5px 15px;
  border-radius: 4px;
  background-color: #804da8;
  color: #ffffff;
  margin: 1px 5px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  &:hover {
      opacity: 1;
      background-color: #33373e;
  }
}

.button {
  display: inline-block;
  padding: 5px 15px;
  border-radius: 4px;
  background-color: #804da8;
  color: #ffffff;
  margin: 1px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
      opacity: 1;
      background-color: #33373e;
  }
  &:disabled {
      opacity: 0.6;
      cursor: default;
  }
}
button.button {
  border: none;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  .content {
    max-width: 1580px;
    width: 100%;
    margin: 0 auto;
  }

  section {
    margin-left: 220px;
    margin-top: 42px;

    h1 {
      margin: 0;
      padding: 10px 20px;
      background-color: #fafafa;

      .search {
        border: 1px solid transparent;
        font-size: 18px;
        background: transparent;
        border-left-color: rgba(0,0,0,.25);
        margin-left: 15px;
        padding: 5px 15px;
        width: 50%;
      }

      .button ,.downloadButton {
        float: right;
      }
    }
  }
}


body section table.list, .table {
  thead {
    th {
      text-align: left;
      padding: 5px 8px;
      font-weight: bold;
      color: #333333;
      background-color: #f7f7f7;
      border-top: 1px solid #ffffff;
      &.sortable {
        cursor: pointer;
      }
    }
  }

  border: none;
  border-collapse: collapse;
  width: 100%;

  .sorted {
    background: rgba(0,0,0,.03);
  }

  .arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    margin-left: 5px;
    opacity: .7;

    &.right {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }

    &.left {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }

    &.up {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }

    &.down {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }

  }

  .box {
    width: 20px;
    height: 20px;
    background-color: #999999;
    display: block;
    &.green {
      background-color: darkgreen;
    }
    &.red {
      background-color: #b10c0c;
    }
    &.transparent {
      background-color: transparent;
    }
    &.white {
      background-color: #ffffff;
    }
    &.grey {
      background-color: #666666;
    }
  }

  .row_deleted{
    background-color: #eee;
    color: gray;
    border: 1px solid grey;
    cursor: not-allowed;
  }
  
  tr {
    .align-center {
      text-align: center;
    }
    &:hover {
      td {
        border-bottom: 1px solid #804da8;
        .icon {
          opacity: 1;
          // &:hover {
          //   opacity: 1;
          // }
        }
      }
    }

    td {
      text-align: left;
      padding: 5px 8px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      .icon {
        opacity: 0.7;
        transition: opacity 0.05s;
      }
    }
    &.organization {
      background-color: rgba(0, 0, 0, 0.1);
      td:first-child {
        border-left: 0 solid rgba(255, 255, 255, 0.5);
        padding-left: 10px;
      }

      &.lvl1 {
        td:first-child {
          border-left-width: $padding * 1px;
        }
      }
      &.lvl2 {
        td:first-child {
          border-left-width: $padding * 2px;
        }
        &.transparent {
          background-color: transparent;
        }
        &.white {
          background-color: #ffffff;
        }
        &.grey {
          background-color: #666666;
        }
      }
      &.lvl3 {
        td:first-child {
          border-left-width: $padding * 3px;
        }
      }
      &.lvl4 {
        td:first-child {
          border-left-width: $padding * 4px;
        }
      }
      &.lvl5 {
        td:first-child {
          border-left-width: $padding * 5px;
        }
      }
    }
  }
}

.container-header {
  background-color: #fafafa;
}
.bold {
  font-weight: bold;
}
.text-left {
  text-align: left;
}
.disabled {
  opacity: 0.6;
  cursor: default;
}