$size: 20px;

#paginated-footer {
  font-size: $size;
  background-color: #f7f7f7;
  padding: 6px 8px 5px;
  font-weight: bold;
  color: #333333;
  .left-arrow {
    margin-right: $size;
  }
  .right-arrow {
    margin-left: $size;
  }
}
